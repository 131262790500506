import React from 'react';
import setcastLogo from './../images/Setcast_Logo_Icon_White_RGB.svg';
import setcastLogoicon from './../images/setcast-logo.svg';
import './../App.css';

function Footer() {
    const userRole = localStorage.getItem("userRole");

    const TalentFooter = () => {
        return (
            <div className="footer black-bg">
                <div className="footer-content">
                    <div className="footer-text">
                        Please contact <strong>Set Cast</strong> in case of questions, longer inactivity like vacation or when you move city. <br/>
                        <strong>For help please contact: <a href="mailto:support@set-cast.com">support@set-cast.com</a></strong>

                    </div>
                    <div className="footer-logo-container">
                        <img style={{ height: 80 }} src={setcastLogoicon} alt="Setcast Logo" />
                    </div>
                </div>
            </div>
        );
    };

    const DefaultFooter = () => {
        return (
            <div className="footer black-bg">
                <div className="footer-logo-container">
                    <img style={{ height: 60 }} src={setcastLogo} alt="Setcast Logo" />
                </div>
            </div>
        );
    };

    return userRole === 'talent' ? <TalentFooter /> : <DefaultFooter />;
}

export default Footer;
